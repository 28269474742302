/*    
<summary>
  This functional component "Add Branch" provides form to add new branch or update existing branch.
  Developer:Mohammad Saquib Khan, Created Date:12-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import config from "../../../helpers/config-helper";
import { getTenantAccessFromLocalStorage } from "../../../helpers/localstorage-helper";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikDateFieldMUI";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import "./request-csv-downloads-form.css";
import { formatMessage } from "../../../translations/format-message";
import toast from "react-hot-toast";
import dayjs from "dayjs";

interface IProps {
  tenantOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isDataLoading: boolean;
  isLoading: boolean;
  initialValues: any;
}

const appConfig = config();
const dateFormat = appConfig.REACT_APP_DATE_TIME_FORMAT;

const RequestCSVDownloadsForm = (props: IProps) => {

  const validateDate = (value: any) => {
    let error;
    if (value === null) error = "field_required";
    else if (value?.$d && value?.$d.toString() === "Invalid Date")
      error = "invalid_date_format";
    return error;
  };

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        enableReinitialize
        // validationSchema={RequestCSVDownloadsValidation(props.tenantOptions)}
        validateOnBlur={false}
        onSubmit={async (values) => {
          let tempStartDate = values.StartDate?.$d
            ? moment(values.StartDate.$d)
            : moment(values.StartDate);

          let tempEndDate = values.EndDate?.$d
            ? moment(values.EndDate.$d)
            : moment(values.EndDate);

          if (moment(values.StartDate.$d).isAfter(moment(values.EndDate.$d))) {
            toast.error(formatMessage("start_date_greater_end"));
          } else {
            props.submitHandler({
              StartDate: tempStartDate,
              EndDate: tempEndDate,
              TenantId: getTenantAccessFromLocalStorage(),
            });
          }
        }}
      >
        {({ dirty }) => {
          return (
            <Form className="user-form">
              {(props.isDataLoading || props.isLoading) && (
                <FormLoader loading={props.isDataLoading || props.isLoading} />
              )}
              <Modal.Body>
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-4 col-form-label"
                    required={true}
                    label="start_date"
                  />
                  <div className="col-sm-8">
                    <Field
                      name="StartDate"
                      validate={validateDate}
                      min={dayjs(Date.now()).subtract(30, 'days')}
                      disableFuture={true}
                      as={FormikDateFieldMUI}
                    />
                  </div>
                </div>
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-4 col-form-label"
                    required={true}
                    label="end_date"
                  />
                  <div className="col-sm-8">
                    <Field
                      name="EndDate"
                      min={dayjs(Date.now()).subtract(30, 'days')}
                      disableFuture={true}
                      validate={validateDate}
                      as={FormikDateFieldMUI}
                    />
                  </div>
                </div>
                {/* 
                <div className="form-group mb-1 row">
                  <Label
                    className="label-style col-sm-4 col-form-label"
                    required={true}
                    label="select_tenant"
                  />
                  <div className="col-sm-8">
                    <Field
                      name="TenantId"
                      className="form-control"
                      as={FormikFormSelect}
                      options={props.tenantOptions}
                    />
                  </div>
                </div> */}
              </Modal.Body>
              <div className="modal-footer">
                <div className="row tab-action g-0">
                  <div className="col-12">
                    <Button
                      variant="light-danger"
                      type="button"
                      onClick={props.onModalClosed}
                      disabled={props.isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>
                    <LoaderButton
                      type="submit"
                      isLoading={props.isLoading}
                      text="button_save"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RequestCSVDownloadsForm;
