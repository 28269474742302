/*    
<summary>
   This component is Managing Media Management & performing CRUD operations on the Media Data.
   CRUD (Create, Read, Update, Delete)
   Developer: Mohammad Saquib Khan, Created Date:14-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./media.css";

import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import "react-sliding-side-panel/lib/index.css";
import noMessageIcon from "../../assets/Images/svg/018-empty.svg";
import folderCategoryTypeEnum from "../../constants/enums/folder-category-type-enum";
import { MediaType } from "../../constants/enums/media-type-enums";
import { ModuleType } from "../../constants/enums/module-type-enum";
import userSettingEnum from "../../constants/enums/user-setting-enum";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/store-provider";
import {
  isOperationPermittedRoleBase,
  showIconsRoleBase,
} from "../../helpers/action-permission-helper";
import {
  getIsTenantAccessFromLocalStorage,
  getTenantAccessFromLocalStorage,
} from "../../helpers/localstorage-helper";
import IPageInfo, {
  IActionFormatter,
  IPageLimitInfo,
} from "../../models/ICommon";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import Grid from "../../shared-components/Grid/Grid";
import { CustomActionFormatter } from "../../shared-components/Grid/GridFormatter";
import Pagination from "../../shared-components/Grid/Pagination";
import DeletePopup from "../../shared-components/popup/delete/delete-popup";
import DeletePopupHOC from "../../shared-components/popup/delete/delete-popup-hoc";
import { formatMessage } from "../../translations/format-message";
import AddEditMedia from "./components/add-edit-media/add-edit-media";
import ViewVideoImage from "./components/html-view/html-view-media";
import UploadMedia from "./components/upload-media/upload-media";
import AddMediaPopupHOC from "./hoc/AddMediaPopupHOC";
import UploadMediaPopupHOC from "./hoc/UploadMediaPopupHOC";
import ViewMediaPopupHOC from "./hoc/ViewMediaPopupHOC";

const Media = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const folderObj: any = location.state;
  const { mediaStore, preferencesStore, authStore } = useStore();
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    getTenantAccess,
    getMediaAccess,
    setTenantAccess,
    resetUnwantedModules,
  } = preferencesStore;
  const { getUserType } = authStore;
  const {
    GetMediaListService,
    inProgress,
    mediaList,
    allMediaList,
    addUpdateMediaState,
    imageMediaDetail,
    videoMediaDetail,
    resetAddUpdateMediaState,
    resetDeleteMediaState,
    resetImageMediaDetail,
    resetVideoMediaDetail,
    deleteMediaState,
    DeleteMediaImageService,
    DeleteMediaVideoService,
    mediaState,
  } = mediaStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedMediaType, setSelectedMediaType] = useState<number>(-1);
  const allMedia: Array<any> = allMediaList;
  const pageLimitOptions: Array<number> = [10, 50, 100];
  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllMedia" store funtion that get Media list from API
   */
  const callGetMediaListService = () => {
    GetMediaListService(
      pageInfo.currentPage,
      pageLimitInfo.pageLimit,
      getTenantAccessFromLocalStorage(),
      getMediaAccess.folderId
    );
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      callGetMediaListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "MediaList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: mediaList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetMediaListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * This function shows the add Media pop up from where we can add new Media and update existing Media. we use id to identify
   * that we are adding or updating Media.
   * "Id == -1" : we are adding new Media
   * "Id != -1" : means we are updating existing Media
   */
  const openAddMediaHandler = (
    row: { MediaId: number; MediaType: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    setSelectedId(row.MediaId);
    setSelectedMediaType(row.MediaType);
    setSelectedRow(row);
    resetImageMediaDetail();
    resetVideoMediaDetail();
    props.addMediaToggleHandler();
  };

  const openUploadMediaHandler = (
    row: { MediaId: number; MediaType: number },
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closePanelhandler();
    event.stopPropagation();
    setSelectedId(row.MediaId);
    setSelectedMediaType(row.MediaType);
    props.uploadMediaToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetMediaListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateMediaState.success" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateMediaState.success) {
      resetAddUpdateMediaState();
      callGetMediaListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateMediaState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "DeleteMediaState" of the useEffect changed.
   */
  useEffect(() => {
    if (deleteMediaState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteMediaState();
      props.deletePopupToggleHandler();
      callGetMediaListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMediaState.success]);

  /**
   * This function deletes the Media by providing Id to the Api
   */
  const deleteHandler = () => {
    if (selectedMediaType == 0) {
      DeleteMediaImageService(selectedId, getTenantAccessFromLocalStorage());
    } else {
      DeleteMediaVideoService(selectedId, getTenantAccessFromLocalStorage());
    }
  };

  /**
   * This function shows the delete media confirmation popup component and sets the seleted media Id
   */
  const deleteClickHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.MediaId);
    setSelectedMediaType(row.MediaType);
    event.stopPropagation();
    closePanelhandler();
    resetImageMediaDetail();
    resetVideoMediaDetail();
    props.deletePopupToggleHandler();
  };

  const openHTMLViewMediaHandler = (
    row: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedId(row.MediaId);
    setSelectedMediaType(row.MediaType);
    event.stopPropagation();
    closePanelhandler();
    resetImageMediaDetail();
    resetVideoMediaDetail();
    props.viewMediaToggleHandler();
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (language !== lang) {
      callGetMediaListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetMediaListService();
  };

  function rowClassFormat(row: any, rowIdx: number) {
    let className: string = "";
    if (selectedRow?.Id && row.Id === selectedRow.Id) {
      className = "bg-row SelectedRow";
    }
    return className;
  }

  const rowEvents = (e: SyntheticEvent, row: any, rowIndex: number) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelhandler = () => {
    setSelectedRow({});
    setOpenPanel(false);
  };

  const exitTenantHandler = () => {
    setTenantAccess({
      isAccessTenantActive: false,
      tenantId: 0,
      tenantName: "",
      orgId: "",
    });
    resetUnwantedModules();
    history.push(RoutesConstants.TenantManagement);
  };

  const actionIcons: IActionFormatter[] = showIconsRoleBase(
    [
      {
        dataField: "isView",
        handler: openHTMLViewMediaHandler,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="currentColor"
            className="bi bi-filetype-html"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z"
            />
          </svg>
        ),
        isDisable: true,
        title: "view_html",
        varient: "primary",
        action: "view",
      },
      {
        dataField: "isEdit",
        handler: openAddMediaHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="21"
            height="21"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.43 24.75"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <path d="M10.53,15.53l1.19,1.19,1.19,1.19-1.85,.58-1.61,.5,.5-1.61,.58-1.85m-.45-1.81l-1.05,3.37-1.05,3.37,3.37-1.05,3.37-1.05-2.32-2.32-2.32-2.32h0Z" />
                <polygon points="7.97 20.46 8.57 18.54 9.17 16.62 10.49 17.94 11.81 19.26 9.89 19.86 7.97 20.46" />
                <path d="M15.22,19.37l-6.15-6.15L22.28,0l6.15,6.15-13.21,13.21Zm-3.12-6.15l3.12,3.12L25.4,6.15l-3.12-3.12L12.1,13.21Z" />
                <path d="M23.57,24.75H4.47c-2.46,0-4.47-2-4.47-4.47v-6.38c0-2.46,2-4.47,4.47-4.47h2.36v2.24h-2.36c-1.23,0-2.23,1-2.23,2.23v6.38c0,1.23,1,2.23,2.23,2.23H23.57c1.23,0,2.23-1,2.23-2.23v-7.47h2.24v7.47c0,2.46-2,4.47-4.47,4.47Z" />
                <rect
                  x="13.01"
                  y="8.8"
                  width="11.25"
                  height="1.3"
                  transform="translate(-1.22 15.95) rotate(-45)"
                />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "edit",
        varient: "primary",
        action: "edit",
      },
      {
        dataField: "isDelete",
        handler: deleteClickHandler,
        icon: (
          <svg
            id="Layer_2"
            fill="#0091ff"
            width="18"
            height="18"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.09 20.66"
          >
            <g id="_レイヤー_3" data-name="レイヤー 3">
              <g>
                <polygon points="12.12 1.99 12.12 0 10.05 0 7.97 0 7.97 1.99 0 1.99 0 4.07 10.05 4.07 20.09 4.07 20.09 1.99 12.12 1.99" />
                <path d="M14.29,17.76c-.02,.37-.32,.66-.69,.66H6.49c-.37,0-.67-.29-.69-.65l-.53-12.44-2.24,.09,.53,12.45c.08,1.56,1.37,2.78,2.93,2.78h7.11c1.56,0,2.85-1.22,2.93-2.79l.53-12.44-2.24-.09-.53,12.43Z" />
                <rect x="11.68" y="6.8" width="1.16" height="9.8" />
              </g>
            </g>
          </svg>
        ),
        isDisable: true,
        title: "delete",
        varient: "danger",
        action: "delete",
      },
    ],
    ModuleType.MediaManagement
  );

  /**
   * The column constant defines the column description for the branch grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "MediaName",
      text: formatMessage("media_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "MediaType",
      text: formatMessage("media_type"),
      formatter: (cell: any, row: any) => {
        return <span>{MediaType[row?.MediaType]}</span>;
      },
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "PricingValue",
      text: formatMessage("digit_value"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      hidden:
        folderObj.FolderCategoryType != folderCategoryTypeEnum.PriceImages
          ? true
          : false,
    },
    {
      dataField: "Comment",
      classes: "address-Tenant",
      text: formatMessage("comment"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      hidden:
      folderObj.FolderCategoryType != folderCategoryTypeEnum.PriceImages
        ? true
        : false,
    },
    {
      dataField: "",
      formatter: CustomActionFormatter(actionIcons),
      text: formatMessage("actions"),
      classes: "last-column",
    },
  ];

  return (
    <React.Fragment>
      {inProgress && <FormLoader loading={inProgress} />}
      {props.showAddMediaPopup && (
        <AddEditMedia
          id={selectedId}
          mediaType={selectedMediaType}
          selectedFolderType={Number(folderObj.FolderCategoryType)}
          modalClosed={props.addMediaToggleHandler}
        />
      )}
      {props.showDeletePopup && (
        <DeletePopup
          title="delete_media"
          modalSubmit={deleteHandler}
          modalClosed={props.deletePopupToggleHandler}
          message="message_delete_media_confirm"
          isLoading={deleteMediaState.inProgress}
        />
      )}

      {props.showUploadMediaPopup && (
        <UploadMedia
          id={selectedId}
          mediaType={selectedMediaType}
          modalClosed={props.uploadMediaToggleHandler}
        />
      )}

      {props.showViewMediaPopup && (
        <ViewVideoImage
          id={selectedId}
          mediaType={selectedMediaType}
          base64={imageMediaDetail.Base64}
          sourcePath={videoMediaDetail.SourcePath}
          mediaName={
            selectedMediaType == 0
              ? imageMediaDetail.ImageName
              : videoMediaDetail.VideoName
          }
          isLoading={mediaState.inProgress}
          modalClosed={props.viewMediaToggleHandler}
        />
      )}
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="MediaManagement" />
              </h1>
              <ol className="breadcrumb">
                {/* <li title={formatMessage("back")}>
                  <button onClick={()=>history.push(RoutesConstants.FoldersManagement)} >
                    <img src={backIcon} />
                  </button>
                </li> */}
                <li title={formatMessage("back")} className="back-icon pe-1">
                  <a
                    onClick={() =>
                      history.push(RoutesConstants.FoldersManagement)
                    }
                  >
                    {/* <img src={backIcon} /> */}
                    <svg
                      version="1.1"
                      height="14"
                      width="14"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 447.243 447.243"
                    >
                      <g>
                        <g>
                          <path
                            d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
			c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
			l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
			c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
			l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
			C449.654,211.494,437.806,195.059,420.361,192.229z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                <li className="breadcrumb-item">{folderObj.FolderName}</li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="MediaManagement" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {/* {isOperationPermittedRoleBase(ModuleType.MediaManagement, 'Add') &&
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FormattedMessage id="add_media" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className="dropdown-item"
                    onClick={(event: any) =>
                      openAddMediaHandler({ MediaId: -1, MediaType: 0 }, event)
                    }
                  >
                    Image
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={(event: any) =>
                      openAddMediaHandler({ MediaId: -1, MediaType: 1 }, event)
                    }
                  >
                    Video
                  </a>
                </div>
              </div>
              } */}

              {isOperationPermittedRoleBase(
                ModuleType.MediaManagement,
                "Add"
              ) && (
                <li title={formatMessage("add_media")} className="ms-1">
                  <a
                    className="btn btn-secondary"
                    data-toggle="modal"
                    onClick={(event: any) =>
                      openAddMediaHandler({ MediaId: -1, MediaType: -1 }, event)
                    }
                    data-testid="modalbtn"
                    data-target="#addUser"
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus m-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <FormattedMessage id="add_media" />
                  </a>
                </li>
              )}

              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {allMedia.length > 0 ? (
        <div>
          <div className="card">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allMedia && (
                  <Grid
                    data={allMedia}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                    // rowClasses={rowClassFormat}
                    // rowEvents={{ onClick: rowEvents }}
                  />
                )}
              </div>
            </div>
          </div>
          {allMedia.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={false}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            <FormattedMessage id="no_media" />
          </p>
          <p className="mb-3">{formatMessage("no_media_message")}</p>
          {/* {isOperationPermittedRoleBase(ModuleType.MediaManagement,'Add') && 
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FormattedMessage id="add_media" />
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                onClick={(event: any) =>
                  openAddMediaHandler({ MediaId: -1, MediaType: 0 }, event)
                }
              >
                Image
              </a>
              <a
                className="dropdown-item"
                onClick={(event: any) =>
                  openAddMediaHandler({ MediaId: -1, MediaType: 1 }, event)
                }
              >
                Video
              </a>
            </div>
          </div>
          } */}
          {isOperationPermittedRoleBase(ModuleType.MediaManagement, "Add") && (
            <div title={formatMessage("add_media")} className="ms-1 ">
              <a
                className="btn btn-primary"
                data-toggle="modal"
                onClick={(event: any) =>
                  openAddMediaHandler({ MediaId: -1, MediaType: -1 }, event)
                }
                data-testid="modalbtn"
                data-target="#addUser"
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-plus m-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <FormattedMessage id="add_media" />
              </a>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewMediaPopupHOC(
  UploadMediaPopupHOC(DeletePopupHOC(AddMediaPopupHOC(observer(Media))))
);
