import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./slider.css"

interface IProps {
    value: number[];
    className?: string;
    count?: number;
    min: number;
    max: number;
    categoryType: number;
    submitHandler: (event:Event,value: number | number[],categoryType:number) => void;
  }
  

const SliderComponentSingle:React.FC<IProps> = (props) => {

  const handleChange = (event: Event, value: number | number[]) => {
    props.submitHandler(event, value, props.categoryType);
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={props.value}
        min={props.min}
        max={props.max}
        onChange={handleChange}
        valueLabelDisplay="auto"
        // getAriaValueText={valuetext}
        sx={{
          "& .MuiSlider-track": {
            bgcolor: "#F39800", // Change the color of the track
            border: "none",
            opacity: 1,
          },
          "& .MuiSlider-rail": {
            bgcolor: "#F39800", // Change the color of the rail (background track)
            opacity: 1,
          },
          '& .MuiSlider-thumb[data-index="0"]': {
            bgcolor: "#F39800", // Change the color of the first thumb
          },
          "& .MuiSlider-valueLabel": {
            //   bgcolor: 'blue', // Change the background color of the value label
          },
        }}
      />
    </Box>
  );
};
export default SliderComponentSingle;
